import React, { Component } from 'react'
import '../styles/views/About.css'
import Hero from '../components/Hero'
import AgencyCard from '../components/AgencyCard'
import diversity from '../assets/images/diversity.jpg'
import culture from '../assets/images/culture.jpg'
import solutionist from '../assets/images/solutionist.jpg'
import branding from '../assets/images/branding.jpg'
import entrepreneur from '../assets/images/entrepreneur.jpg'
import digital from '../assets/images/digital.jpg'
import marketing from '../assets/images/experiential.jpg'
import media from '../assets/images/media.jpg'
import pr from '../assets/images/pr.jpg'
import sports from '../assets/images/sports.jpg'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

class About extends Component {
    render() {
      return (
        <div className='about'>
          <div className='container wow fadeIn' data-wow-duration='2s'>
            <div className='row double-row-padding'>
              <div className='col-sm-6'>
                <h1>
                  Welcome to <br />the Republicom <br />Group
                </h1>
              </div>
              <div className='col-sm-6'>
                <p>
                  With 54 countries, over 1,500 languages, 
                  30 million square kilometers and the youngest 
                  youth population in the world, we recognise the 
                  similarities and common trends but we also understand 
                  that even within the same demographic group, 
                  the motivations and behaviours can be so different. 
                </p>
                <p>
                  We understand that within the same language the same 
                  word could mean ‘come’ and ‘drive’ depending on the 
                  accent sign on the word. 
                </p>
              </div>
            </div>
          </div>
          <div className='container wow fadeIn' data-wow-duration='2s'>
          <div className='row double-row-padding'>
            <div className='col-sm-12'>
              <OwlCarousel
                className="owl-theme"
                loop
                nav
                items={1}
              >
                  <div className="item">
                    <div className='row'>
                      <div className='col-sm-6 col-md-7'>
                        <strong>
                          <small>CORE VALUES</small>
                        </strong>
                        <br /><br />
                        <h2>Workforce diversity</h2>
                        <p>
                          We bring together a variety of people with a wide range 
                          of experiences, backgrounds and characteristics. 
                          It makes Republicom a great place to work. There is beauty 
                          and richness in divergent views and experiences which 
                          ultimately leads to more empathetic, inclusive and creative work.
                        </p>
                      </div>
                      <div className='col-sm-6 col-md-5'>
                        <img src={diversity} className='value-illustration' />
                      </div>
                    </div>
                  </div> 
                  <div className="item">
                    <div className='row'>
                      <div className='col-sm-6 col-md-7'>
                        <strong>
                          <small>CORE VALUES</small>
                        </strong>
                        <br /><br />
                        <h2>Culturally attuned</h2>
                        <p>
                          Culture plays a critical role in communications. 
                          The ability to understand innate behaviours, customs and 
                          mindsets, to walk a mile in someone else’s shoes, to broaden 
                          our perspective of the world. Understanding a multicultural 
                          continent is at the heart of our pursuit to do work that matters.
                        </p>
                      </div>
                      <div className='col-sm-6 col-md-5'>
                        <img src={culture} className='value-illustration' />
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className='row'>
                      <div className='col-sm-6 col-md-7'>
                        <strong>
                          <small>CORE VALUES</small>
                        </strong>
                        <br /><br />
                        <h2>Entrepreneurial Spirit</h2>
                        <p>
                          Our culture is deeply entrepreneurial, 
                          and we maintain a problem solving mindset. 
                          We are always conscious of the impact of 
                          our work on our communities.
                        </p>
                      </div>
                      <div className='col-sm-6 col-md-5'>
                        <img src={entrepreneur} className='value-illustration' />
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className='row'>
                      <div className='col-sm-6 col-md-7'>
                        <strong>
                          <small>CORE VALUES</small>
                        </strong>
                        <br /><br />
                        <h2>Solutionist</h2>
                        <p>
                          We desire to solve problems that lead to change. 
                          We believe brands can create solutions to global problems. 
                          Therefore, we advice and co-create ways for brands to make an impact.
                        </p>
                      </div>
                      <div className='col-sm-6 col-md-5'>
                        <img src={solutionist} className='value-illustration' />
                      </div>
                    </div>
                  </div>
              </OwlCarousel>
            </div>
          </div>
          </div>
          <br />
          { /* <div className='container'>
            <div className='row double-row-padding'>
              <div className='col-sm-6'>
                <h2>
                  Who we are
                </h2>
              </div>
              <div className='col-sm-6'>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                </div>
            </div>
          </div> */ }
          <div className='container double-row-padding-bottom'>
            <div className='row'>
              <div className='col-sm-6'>
                <h2>
                  Our Agencies
                </h2>
              </div>
            </div>
            <br />
            <div className='row wow fadeIn' data-wow-duration='2s'>
              <AgencyCard 
                 image={media}
                 name='Quore Media'
                 service='Branded Content'
                 link='http://quoremedia.com.ng/'
              />
              <AgencyCard 
                 image={pr}
                 name='Redwood Consulting'
                 service='Business Advisory and Insights'
                 link='https://www.redwood-consulting.com/'
              />
              <AgencyCard 
                 image={branding}
                 name='Image & Time'
                 service='Design and Creative Agency'
                 link='https://imageandtime.com'
              />
              <AgencyCard 
                 image={digital}
                 name='Neukleos'
                 service='Digital First Solutionist'
                 link='https://neukleos.com'
              />
              <AgencyCard 
                 image={sports}
                 name='Integral'
                 service='Sports Marketing'
                 link='http://www.integralsande.com/'
              />
              <AgencyCard 
                 image={marketing}
                 name='Connect Marketing'
                 service='Trade and Experiential Marketing'
                 link='http://connectmarketingonline.com/'
              />
            </div>
            { /* <div className='row double-row-padding-bottom'>
              <div className='col-sm-12'>
                <h2>
                  Another Section
                </h2>
                <br />
              </div>
              <div className='col-sm-6'>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
              <div className='col-sm-6'>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div> */ }
          </div>
        </div>
      );
    }
}

export default About;