import React, { Component } from 'react'
import '../styles/components/Header.css'
import logo from '../assets/images/logo.png'
import { NavLink, withRouter} from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

class Header extends Component{

  static propTypes = {
    history: PropTypes.object.isRequired
  }
  state = {
    show: false,
    showside: false
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll = () => {
    window.scrollY > 75 ? this.setState({ showside: true }) : this.setState({ showside: false })
    // console.log(window.scrollY)
  }
  handleOpen = () => {
    this.setState({
      show: true
    })
  }
  handleClose = () => {
    this.setState({ show: false });
  }
  toHome = () => {
    this.setState({ show: false })
    this.props.history.push('/')
  }
  toAbout = () => {
    this.setState({ show: false })
    this.props.history.push('/about')
  }
  toPeople = () => {
    this.setState({ show: false })
    this.props.history.push('/people')
  }
  toNews = () => {
    this.setState({ show: false })
    this.props.history.push('/news')
  }
  toCareers = () => {
    this.setState({ show: false })
    this.props.history.push('/careers')
  }
  toContact = () => {
    this.setState({ show: false })
    this.props.history.push('/contact')
  }
  render(){ 
    // console.log("Props", this.props)
    return (
      <div>
        <header>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-xs-6'>
                <NavLink to='/' exact>
                  <img 
                    src={logo}
                    className='logo'
                  />
                </NavLink>
              </div>
              <div className='col-sm-6 col-xs-6 text-right'>
                <i className='fa fa-bars' onClick={this.handleOpen} ></i>
              </div>
            </div>
          </div>
        </header>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className='container'>
              <div className='row'>
                <div className='col-sm-12'>
                  <h2 className={`navigation-link ${this.props.location.pathname === '/'}`} onClick={this.toHome}>Home</h2>
                  <h2 className={`navigation-link ${this.props.location.pathname === '/about'}`} onClick={this.toAbout}>About</h2>
                  <h2 className={`navigation-link ${this.props.location.pathname === '/people'}`} onClick={this.toPeople}>People</h2>
                  <h2 className={`navigation-link ${this.props.location.pathname === '/news'}`} onClick={this.toNews}>News</h2>
                  <h2 className={`navigation-link ${this.props.location.pathname === '/careers'}`} onClick={this.toCareers}>Careers</h2>
                  <h2 className={`navigation-link ${this.props.location.pathname === '/contact'}`} onClick={this.toContact}>Contact</h2>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {this.state.showside === true && <div className="sidebar-nav" onClick={this.handleOpen}><i className='fa fa-bars'></i></div>}
      </div>
    )
  }
}

export default withRouter(Header);