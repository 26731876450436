import React, { Component } from 'react'
import '../styles/views/Home.css'
import hero from '../assets/images/hero.jpg'
import { NavLink } from 'react-router-dom'
import techplus1 from '../assets/images/techplus-carousel1.jpg'
import techplus2 from '../assets/images/techplus-carousel2.jpg'
import techplus3 from '../assets/images/techplus-carousel3.jpg'
import techpluslogo from '../assets/images/techplus-logo.png'
import awa1 from '../assets/images/awa-carousel1.jpg'
import awa2 from '../assets/images/awa-carousel2.jpg'
import awa3 from '../assets/images/awa-carousel3.jpg'
import awalogo from '../assets/images/awa-logo.png'
import republicomcollege1 from '../assets/images/republicomcollege-carousel1.jpg'
import republicomcollege2 from '../assets/images/republicomcollege-carousel2.jpg'
import republicomcollege3 from '../assets/images/republicomcollege-carousel3.jpg'
import republicomcollegelogo from '../assets/images/republicomcollege-logo.png'
import Hero from '../components/Hero'
import SquareImage from '../components/SquareImage'
import { latest } from '../utils/api'
import renderHTML from 'react-render-html'

// Services
import communication from '../assets/images/home_communication.jpg'
import consulting from '../assets/images/home_consulting.jpg'
import productsandservices from '../assets/images/home_productsandservices.jpg'
import sportsmarketing from '../assets/images/home_sportsmarketing.jpg'
import studios from '../assets/images/home_studios.jpg'
import ventures from '../assets/images/home_ventures.jpg'

class Home extends Component {
    state = {
        feed: null
    }
    componentDidMount(){
        document.querySelector('.home-scroll-down').onclick = function(){ 
            window.scroll({top: document.documentElement.clientHeight, left: 0, behavior: 'smooth' })
        }
        this.fetchLatestNews()
    }

    fetchLatestNews = () => {
        latest().then((latest) => {
            // console.log(latest)
            this.setState({ feed: latest })
        })
    }

    formatDate = (date) => {
        var ts = new Date(date);
        return ts.toDateString()
    }

    render() {
        return ( 
          <div className='home'>
            <Hero 
              image={hero}
              overlay={true}
              height='calc(100vh - 85px)'
              text={`<h1 className='wow fadeIn' data-wow-duration='2s' >We are a<br />culturally attuned partner<br />for business transformation<br />in Africa<br /><br /><i className="fas fa-chevron-down home-scroll-down"></i></h1>`}
            /> 
            <section className='container' id="home-first-content-row">
                <div className='row info-row row-padding wow fadeIn' data-wow-duration='5s'>
                    <div className='col-sm-6'>
                        <p> 
                            We are an African brand with a global challenger mindset. 
                            Africa is home to over 1.2 billion people, a continent of colour, 
                            heritage and vibrance, we act as a bridge to Africa’s largest markets. 
                        </p>
                        <p>
                            We understand the tonality and nuances of the environment, 
                            the latent energy and the deep insights driving behaviour across the continent. 
                        </p>
                    </div>
                    <div className='col-sm-6'>
                        <p>
                            As a group, we aim to foster creative transformation, 
                            advocate for African representation in global marketing, 
                            deliver impact at scale and build unique experiences through 
                            thought leadership, innovation and a culturally attuned workforce.
                        </p>
                    </div>
                </div>
            </section>
            <section className="container">
                <div className="row wow fadeIn" data-wow-duration='5s'>
                    <div className="col-sm-12">
                        <h2>Our Services</h2>
                        <br />
                    </div>
                </div>
                <section className='row'>
                    <div className='col-sm-6 col-md-4'>
                        <div className='wow zoomIn' data-wow-duration='2s'>
                            <SquareImage image={communication}/>
                        </div>
                        <h3 className="wow fadeIn" data-wow-duration='5s'>Communications</h3>
                        <p className='service-description wow fadeIn' data-wow-duration='5s'>We create connected and contextual campaigns designed to be culturally relevant</p>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='wow zoomIn' data-wow-duration='2s'>
                            <SquareImage image={consulting} />
                        </div>
                        <h3 className="wow fadeIn" data-wow-duration='5s'>Consulting</h3>
                        <p className='service-description wow fadeIn' data-wow-duration='5s'>We help the C-Suite innovate by designing services and brands needed to win</p>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='wow zoomIn' data-wow-duration='2s'>
                            <SquareImage image={productsandservices} />
                        </div>
                        <h3 className="wow fadeIn" data-wow-duration='5s'>Products and Services</h3>
                        <p className='service-description wow fadeIn' data-wow-duration='5s'>We design products and immersive consumer experiences</p>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='wow zoomIn' data-wow-duration='2s'>
                            <SquareImage image={sportsmarketing} />
                        </div>
                        <h3 className="wow fadeIn" data-wow-duration='5s'>Sports marketing</h3>
                        <p className='service-description wow fadeIn' data-wow-duration='5s'></p>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='wow zoomIn' data-wow-duration='2s'>
                            <SquareImage image={studios} />
                        </div>
                        <h3 className="wow fadeIn" data-wow-duration='5s'>Studios</h3>
                        <p className='service-description wow fadeIn' data-wow-duration='5s'>We create branded content and curate relevant content through partnerships</p>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='wow zoomIn' data-wow-duration='2s'>
                            <SquareImage image={ventures} />
                        </div>
                        <h3 className="wow fadeIn" data-wow-duration='5s'>Ventures</h3>
                        <p className='service-description wow fadeIn' data-wow-duration='5s'>We bring together innovators and industry leaders to enhance innovation and create shared value</p>
                    </div>
                </section>
            </section>

            { /*<section className='container'>
                <div className='row row-padding'>
                    <div className='col-sm-2'>
                        <a href='https://imageandtime.com' target='_blank'>
                            <img src={imageandtime} className='company-logo' />
                        </a>
                    </div>
                    <div className='col-sm-2'>
                        <a href='https://neukleos.com' target='_blank'>
                            <img src={neukleos} className='company-logo' />
                        </a>
                    </div>
                    <div className='col-sm-2'>
                        <a href='http://www.connectmarketingonline.com/' target='_blank'>
                            <img src={connectmarketing} className='company-logo' />
                        </a>
                    </div>
                    <div className='col-sm-2'>
                        <a href='http://quoremedia.com.ng/' target='_blank'>
                            <img src={quoremedia} className='company-logo' />
                        </a>
                    </div>
                    <div className='col-sm-2'>
                    <a href='https://www.redwood-consulting.com/' target='_blank'>
                            <img src={redwood} className='company-logo' />
                        </a>
                    </div>
                    <div className='col-sm-2'>
                    <a href='http://integralsande.com/' target='_blank'>
                            <img src={integral} className='company-logo' />
                        </a>
                    </div>
                </div>
            </section>*/ }
            <section className="section double-row-padding-top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Our Platforms</h2>
                            <br />
                        </div>
                    </div>
                </div>
                <div className="platforms" style={{ width: '100%' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-tabs nav-justified" role="tablist">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="#techplus" role="tab" data-toggle="tab">TechPlus</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#awa" role="tab" data-toggle="tab">Advertising Week Africa</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#republicomcollege" role="tab" data-toggle="tab">Republicom College</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br /><br />  
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane in active" id="techplus">
                            <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#myCarousel" data-slide-to="1"></li>
                                    <li data-target="#myCarousel" data-slide-to="2"></li>
                                </ol>
                                <div className="carousel-inner wow fadeIn" data-wow-duration='1s'>
                                    <div className="item active">
                                    <img src={techplus1} alt="techplus" />
                                    </div>

                                    <div className="item">
                                    <img src={techplus2} alt="techplus" />
                                    </div>

                                    <div className="item">
                                    <img src={techplus3} alt="techplus" />
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <br /><br /><br />
                                <div className="row">
                                    <div className="col-sm-6 col-md-8">
                                        <p>
                                            TechPlus is a platform that connects, enables and fosters tech inclusion 
                                            for social change and enterprise. Our flagship Technology Conference & 
                                            Expo event is Africa’s largest thought leadership, exhibition and gaming hub.
                                        </p>
                                        <p>
                                        TechPlus brings people, companies and delegates from around the world to 
                                        showcase and learn how technology is evolving to meet the needs of a 
                                        digital generation and modern business. From start-ups to SMEs and Enterprise, 
                                        everyone is welcome. TechPlus offers brands, individuals and governments an 
                                        unrivalled platform for engagement, collaboration and business growth.
                                        </p>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <img src={techpluslogo} className="platform-logo" />
                                        <p className="platform-site">
                                            <a target="_blank" href="http://techplus.com.ng">TechPlus website</a>
                                        </p>
                                        <p className="platform-social-media">
                                            <a target="_blank" href="https://www.facebook.com/techplusng/">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a target="_blank" href="https://twitter.com/techplusng">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a target="_blank" href="https://www.instagram.com/techplusng/">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <br /><br /><br />
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="awa">
                            <div id="awaCarousel" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#awaCarousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#awaCarousel" data-slide-to="1"></li>
                                    <li data-target="#awaCarousel" data-slide-to="2"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="item active">
                                    <img src={awa1} alt="awa" />
                                    </div>

                                    <div className="item">
                                    <img src={awa2} alt="awa" />
                                    </div>

                                    <div className="item">
                                    <img src={awa3} alt="awa" />
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <br /><br /><br />
                                <div className="row">
                                    <div className="col-sm-6 col-md-8">
                                        <p>
                                            Advertising Week is the premier event for leaders from the advertising, 
                                            marketing, media and related creative industries. 
                                            Currently in five different major cities across the globe New York, 
                                            London, Tokyo, Mexico City and Sydney - each edition of Advertising Week 
                                            presents endless opportunities to learn, network and engage in conversations 
                                            about the many issues, opportunities and challenges facing the industry today.
                                        </p>
                                        <p>
                                        The first Advertising Week Africa is scheduled to hold in Johannesburg, 
                                        South Africa in October 2019.
                                        </p>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <img src={awalogo} className="platform-logo" />
                                        <p className="platform-site">
                                            <a href="http://africa.advertisingweek.com/" target="_blank">Advertising Week</a>
                                        </p>
                                        <p className="platform-social-media">
                                            <a href="https://www.facebook.com/Advertising-Week-Africa-2186441528263039/?modal=admin_todo_tour" target="_blank">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="https://twitter.com/AWAfrica_" target="_blank">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="https://www.instagram.com/awafrica_/" target="_blank">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <br /><br /><br />
                            </div>
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="republicomcollege">
                            <div id="rcCarousel" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#rcCarousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#rcCarousel" data-slide-to="1"></li>
                                    <li data-target="#rcCarousel" data-slide-to="2"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="item active">
                                    <img src={republicomcollege1} alt="republicomcollege" />
                                    </div>

                                    <div className="item">
                                    <img src={republicomcollege2} alt="republicomcollege" />
                                    </div>

                                    <div className="item">
                                    <img src={republicomcollege3} alt="republicomcollege" />
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <br /><br /><br />
                                <div className="row">
                                    <div className="col-sm-6 col-md-8">
                                        <p>
                                            As part of the Republicom Group's commitment to transforming 
                                            the African continent through effective service delivery, 
                                            we started The Republicom College. 
                                        </p>
                                        <p>
                                            This is a quarterly industry event designed as a platform 
                                            for the C-Suite to share hands-on-experience from their wealth 
                                            of knowledge with career professionals, business owners and leaders.
                                        </p>
                                        <p>
                                            Republicom College affords mid-level to senior-level managers 
                                            in the marketing industry the opportunity to learn, interact and 
                                            network with professionals from various industries.
                                        </p>
                                    </div>
                                    <div className="col-sm-6 col-md-4">
                                        <img src={republicomcollegelogo} className="platform-logo-rc" />
                                        <p className="platform-site">
                                            <NavLink to="/republicom-college">Republicom College</NavLink>
                                        </p>
                                        { /*<p className="platform-social-media">
                                            <a href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </p> */}
                                    </div>
                                </div>
                                <br /><br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section className='container'>
                <div className='row info-row row-padding'>
                    <div className='col-sm-6'>
                        <h2>Latest news</h2>
                    </div>
                    { this.state.feed !== null 
                        ? <div className='col-sm-6'>
                            <h4>{this.state.feed.title}</h4>
                            
                            { renderHTML(this.state.feed.description) }
                                <p> - 
                                    <strong>
                                     <a href={this.state.feed.link} target="_blank"> Read article</a>
                                    </strong>
                                </p>
                            
                        </div>
                        : <div className='col-sm-6'></div>
                    }
                </div>
            </section>
          </div>
        );
      }
}

export default Home;