import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import '../styles/views/PageNotFound.css'
import pagenotfound from '../assets/images/pagenotfound.svg'

class PageNotFound extends Component {
    render() {
        return (
          <div className='pagenotfound'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-3 col-sm-2'></div>
                <div className='col-md-6 col-sm-8 text-center'>
                  <img src={pagenotfound} />
                  <p>Oops. This page does not exist</p>
                  <br />
                  <NavLink to="/" className='btn'>Go Home</NavLink>
                </div>
                <div className='col-md-3 col-sm-2'></div>
              </div>
            </div>
            
          </div>
        );
      }
}

export default PageNotFound;