import React, { Component } from 'react'
import '../styles/views/People.css'
import { NavLink } from 'react-router-dom'
import hannah from '../assets/images/hannah.jpg'
import eyitayo from '../assets/images/eyitayo.jpg'
import gbonju from '../assets/images/gbonju.jpg'
import sesan from '../assets/images/sesan.jpg'
import lanipekun from '../assets/images/lanipekun.jpg'
import tunji from '../assets/images/tunji.png'
import yetunde from '../assets/images/yetunde.png'
import bukola from '../assets/images/bukola.png'
import kazeem from '../assets/images/kazeem.png'
import tosin from '../assets/images/tosin.png'
import bimbo from '../assets/images/bimbo.jpg'

class People extends Component {
    render() {
        return (
          <div className='people'>
            <div className='container'>
              <div className='row double-row-padding wow fadeIn' data-wow-duration='2s'>
                <div className='col-sm-6'>
                  <h1>
                    Group <br />Executive <br />Team
                  </h1>
                </div>
                <div className='col-sm-6'>
                  <p>
                    Our Executives bring with them a wealth of experience, 
                    having worked in and developed reputable companies across 
                    various markets. Their passion and commitment to success 
                    continues to drive us, making us a formidable African challenger brand.
                  </p>
                </div>
              </div>
              <div className='row row-padding-top'>
                <div className='col-sm-6'>
                  <img src={tunji} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>Global CEO, Republicom</p>
                    <h2>Tunji Adeyinka</h2>
                    <p>
                      Tunji’s experience spans over 27 years in Management, Strategy, 
                      Marketing and Communications. He started his career in Advertising 
                      working with MC&A, a Saatchi and Saatchi agency in 1992. Subsequently, 
                      he worked with the premier Direct Response Marketing company in Nigeria, 
                      Tequila. Tunji has also worked in Sponsorships, Brand Activation, 
                      Sports Marketing, Brand Management, Brand Strategy and Business Management. 
                      He has worked on different brands across business sectors including 
                      Telecommunications, Banking and Finance, FMCG, etc.
                    </p>
                    <p>
                      Tunji has an MBA from the IESE Business School, Universidad de Navarra, 
                      Spain, MSc. International Relations from the University of Ife and a BA 
                      in English and History from the University of Ilorin. 
                      He is a Member of the Lagos Business School Alumni Association and the 
                      Institute of Directors.
                    </p>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <img src={hannah} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>CEO, Republicom Nigeria</p>
                    <h2>Hannah Oyebanjo</h2>
                    <p>
                      Hannah Oyebanjo (MCIM, FISMN) is the CEO, Republicom Nigeria. 
                      She is also the Managing Director of Redwood Consulting; 
                      an outsourced marketing outfit based out of Lagos. 
                    </p>
                    <p>
                      She was Marketing Director, Colgate Palmolive Nigeria till Q4 2016, 
                      and Marketing Director of GlaxoSmithKline for 4 years. 
                      Hannah is a multi award winner at many levels spanning career, 
                      academics, business and social basis with about three decades 
                      experience in brand management, sales and marketing communications.
                    </p>
                  </div>
                </div>
              </div>
              <div className='row row-padding-top'>
                <div className='col-sm-6 wow fadeIn' data-wow-duration='2s'>
                  <h2>Company Directors</h2>
                  <p>
                    Republicom's Directors are leaders in their field, 
                    who’s visionary approach and attention to detail continues 
                    to produce remarkable work. 
                    Their shared philosophy and approach allows them to foster 
                    a community of people determined to produce great work and impact. 
                  </p>
                </div>
              </div>
              <br /><br /><br />
              <div className='row'>
                <div className='col-sm-6 col-md-4'>
                  <img src={kazeem} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>Managing Director</p>
                    <h3>Kazeem Abimbola</h3>
                    <p>Connect Marketing Services</p>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <img src={tosin} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>Public Sector Partnerships</p>
                    <h3>Tosin Omoyajuowo</h3>
                    <p>Connect Marketing Services</p>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <img src={lanipekun} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>Managing Partner</p>
                    <h3>Tosin Lanipekun</h3>
                    <p>Image & Time UK</p>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <img src={gbonju} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>Managing Partner</p>
                    <h3>Gbonjusola Akingbade</h3>
                    <p>Image & Time</p>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <img src={bimbo} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>Managing Partner</p>
                    <h3>Abimbola Ilo</h3>
                    <p>Integral</p>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <img src={yetunde} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>General Manager</p>
                    <h3>Yetunde Akin-Olaiya</h3>
                    <p>Quore Media</p>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <img src={bukola} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>Partner (Innovation)</p>
                    <h3>Bukola Akingbade</h3>
                    <p>Neukleos</p>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <img src={eyitayo} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>Managing Partner</p>
                    <h3>Eyitayo Olayemi</h3>
                    <p>Neukleos</p>
                  </div>
                </div>
                <div className='col-sm-6 col-md-4'>
                  <img src={sesan} className='img-square wow zoomIn' data-wow-duration='1s'/>
                  <div className='team-leads-2 wow fadeIn' data-wow-duration='2s'>
                    <p className='leader-role'>General Manager</p>
                    <h3>Sesan Olukoya</h3>
                    <p>Integral</p>
                  </div>
                </div>
              </div>
              <div className='row double-row-padding-bottom work-with-us wow fadeIn' data-wow-duration='2s'>
                <div className='col-sm-6'>
                  <h2>Work with us</h2>
                  <br /><br />
                </div>
                <div className='col-sm-6'>
                  <p>
                    We have three ever-expanding global offices located in Lagos, 
                    London, and Johannesburg. We offer opportunities to people 
                    from all manner of creative, technical and administrative 
                    backgrounds and we are always interested to hear from people 
                    who want to join the Republicom Group. To learn more, please take a 
                    look at our careers page.
                  </p>
                  <p>
                    <NavLink to="/careers">Current Vacancies > </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      }
}

export default People;