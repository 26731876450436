import React, { Component } from 'react'
import '../styles/components/SquareImage.css'
import PropTypes from 'prop-types'

class SquareImage extends Component{
  static propTypes = {
    image: PropTypes.string.isRequired,
  }
  render() {
    return (
      <div className='square-image-div' style={{background: `url(${this.props.image})`, backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}></div>
    )
  }
}

export default SquareImage;