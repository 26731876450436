import React from 'react'
import '../styles/components/Footer.css'

export default function Footer(){
    return (
      <div className='footer'>
      <div className='container'>
        <div className='row'>
            <div className='col-xs-4 col-sm-6'>
                <p className='social-icons'>
                    { /*<a href='#'><i className="fab fa-facebook-f"></i></a> */ }
                    <a href='mailto:we@therepublicomgroup.com' target="_blank"><i className='fa fa-envelope'></i></a>
                    <a href='https://www.twitter.com/RepublicomGroup/' target="_blank"><i className='fab fa-twitter'></i></a>
                    <a href='https://www.instagram.com/republicom/' target="_blank"><i className='fab fa-instagram'></i></a>
                </p>
            </div>
            <div className='col-xs-8 col-sm-6'>
                <p className='text-right'>&copy; 2019 Republicom. All rights reserved </p>
            </div>
        </div>
      </div>
      </div>
    )
}