import React, { Component } from 'react'
import '../styles/views/RepublicomCollege.css'
import republicomcollege from '../assets/images/republicomcollege-carousel2.jpg'

class RepublicomCollege extends Component {
    render() {
        return (
          <div className='republicomcollege'>
            <div className='container wow fadeIn' data-wow-duration='2s'>
              <div className='row double-row-padding'>
                <div className='col-sm-6'>
                  <h1>
                    Republicom College
                  </h1>
                </div>
                <div className='col-sm-6'>
                  <p>
                    As part of the Republicom Group's commitment to transforming the 
                    African continent through effective delivery of Integrated Communication 
                    Services and Solutions, the Republicom College has put together an 
                    Executive Management Series initiative. 
                    This is a quarterly event designed as a platform for Senior Executives 
                    to share hands-on-experience from their wealth of knowledge with career 
                    professionals, business owners and leaders.
                  </p>
                  </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <img src={republicomcollege} className='img-full wow zoomIn' data-wow-duration='2s'/>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row row-padding-top wow fadeIn' data-wow-duration='2s'>
                    <div className='col-sm-12'>
                        <h2>Upcoming Event</h2>
                    </div>
                </div>
                <div className='row row-padding-bottom wow fadeIn' data-wow-duration='2s'>
                    <div className='col-sm-6 col-md-7'>
                        <br />
                        <h4>Description</h4>
                        <p>
                        ‘Executive Management Series’ is an event designed as a platform for 
                        Senior Executives to share hands-on experience, from their wealth of 
                        knowledge with career professionals, business owners and Leaders.<br /><br />
                        The theme of the next event is "Digital Transformation: The use of new technology to propel business" 
                        and speakers would be announced soon.
                        </p>
                    </div>
                    <div className='col-sm-1 col-md-1'></div>
                    <div className='col-sm-5 col-md-4'>
                        <br />
                        <h4>Date And Time</h4>
                        <p>Wed, June 26, 2019</p>
                        <p>10 AM WAT</p>

                        <br />
                        <h4>Location</h4>
                        <p>Protea Hotel, GRA, Ikeja</p>
                    </div>
                </div>
                <div className='row double-row-padding-bottom wow fadeIn' data-wow-duration='2s'>
                    <div className='col-sm-12'>
                        <h2>Past Events</h2>
                    </div>
                    <div className="col-sm-12 col-md-1">
                    <p className='year'>- 2018</p>
                    </div>
                    <div className="col-sm-12 col-md-11">
                        <div className='event-body'>
                            <button type="button" className="event-title" data-toggle="collapse" data-target="#first">
                            <h3>
                                Unlocking the power of retail
                                <i className='fa fa-chevron-right pull-right'></i>
                            </h3>
                            </button>
                            <div id="first" className="collapse event-description">
                                <div className='row'>
                                    <div className='col-sm-6 col-md-7'>
                                        <br />
                                        <h4>Description</h4>
                                        <p>
                                        ‘Executive Management Series’ is an event designed as a platform for 
                                        Senior Executives to share hands-on experience, from their wealth of 
                                        knowledge with career professionals, business owners and Leaders.<br /><br />
                                        Speaking at this edition themed "Unlocking the Power of Retail," 
                                        are two industry leaders:<br />
                                        - Bunmi Lawson<br />
                                        - Olugbenga Kolawole<br /><br />
                                        With an exclusive attendance of about 35 people, it promises 
                                        to be insightful and expository event that will afford great 
                                        </p>
                                    </div>
                                    <div className='col-sm-1 col-md-1'></div>
                                    <div className='col-sm-5 col-md-4'>
                                        <br />
                                        <h4>Date And Time</h4>
                                        <p>Wed, August 15, 2018</p>
                                        <p>10:30 AM – 12:30 PM WAT</p>

                                        <br />
                                        <h4>Location</h4>
                                        <p>Ikeja, GRA</p>
                                        <p>Lagos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='event-body'>
                            <button type="button" className="event-title" data-toggle="collapse" data-target="#second">
                            <h3>
                                Succeeding with Retail
                                <i className='fa fa-chevron-right pull-right'></i>
                            </h3>
                            </button>
                            <div id="second" className="collapse event-description">
                                <div className='row'>
                                    <div className='col-sm-6 col-md-7'>
                                        <br />
                                        <h4>Description</h4>
                                        <p>
                                        The Republicom College brings you the Executive Management Series.
                                        The ‘Executive Management Series’ is a quarterly event designed as a 
                                        platform for Senior Executives to share hands-on experience, 
                                        from their wealth of knowledge with career professionals, 
                                        Business owners and Leaders. This flagship edition themed 
                                        “Succeeding with Retail” is an exclusive event that will offer 
                                        deep insights and great networking opportunities.<br />
                                        <strong>Our speakers: </strong>
                                        - Inusa bello - NTEL Telecommunication<br />
                                        - Chandana Fernando - Nestle Nigeria Plc<br /><br />

                                        </p>
                                    </div>
                                    <div className='col-sm-1 col-md-1'></div>
                                    <div className='col-sm-5 col-md-4'>
                                        <br />
                                        <h4>Date And Time</h4>
                                        <p>Thu, March 8, 2018</p>
                                        <p>10:00 AM – 12:00 PM WAT</p>

                                        <br />
                                        <h4>Location</h4>
                                        <p>Ikeja, GRA</p>
                                        <p>Lagos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        );
      }
}

export default RepublicomCollege;