import React, { Component } from 'react'
import PropTypes from 'prop-types'
import renderHTML from 'react-render-html'
import '../styles/components/Hero.css'
import { relative } from 'path';

class Hero extends Component{
  static propTypes = {
    image: PropTypes.string.isRequired,
    overlay: PropTypes.bool,
    text: PropTypes.string,
    height: PropTypes.string,
  }
  render() {
    return (
        <div className='hero' style={{ background: `url(${this.props.image})`, backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: this.props.height, width: '100%' }}>
            {this.props.overlay && <div className='overlay'></div>}
            {this.props.text && 
            <div className='container'>
              <div className='row'>
                <div className='col-sm-12' style={{position: 'relative', height: this.props.height }}>
                  {renderHTML(this.props.text)}
                </div>
              </div>
            </div>}
        </div>
    )
  }
}

export default Hero;