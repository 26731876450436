import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import renderHTML from 'react-render-html'
import '../styles/views/News.css'
import { news } from '../utils/api'

class News extends Component {
    state = {
      feeds: null,
      all_feeds: null,
      arrayLength: 0,
      to: 3,
      fetched: false
    }
    componentDidMount(){
      this.fetchNews()
      this.scroll()
    }
    fetchNews = () => {
      news().then((news) => {
        // console.log(news)
        this.setState({ 
          all_feeds: news,  
          arrayLength: news.length,
          feeds: news.slice(0, this.state.to),
          to: this.state.to + 1,
          fetched: true
        })
      })
    }
    scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          if(this.state.to < this.state.arrayLength){
            this.setState({ 
              feeds: this.state.all_feeds.slice(0, this.to),
              to: this.state.to + 1
            })
          }else{
            this.setState({ feeds: this.state.all_feeds })
          }
        }
      };
    }
    formatDate = (date) => {
      var ts = new Date(date);
      return ts.toDateString()
    }
    render() {
        return (
          <div className='news'>
            <div className='container'>
              <div className='row double-row-padding wow fadeIn' data-wow-duration='2s'>
                <div className='col-sm-6'>
                  <h1>
                    Republicom News
                  </h1>
                </div>
                <div className='col-sm-6'>
                  <p></p>
                  </div>
                </div>
                <br />
                { this.state.fetched === false &&
                  <div className='row' >
                    <div className='col-sm-12'>
                      <div className='full-length placeholder thick'></div>
                      <div className='full-length placeholder thin'></div>
                      <div className='third-length placeholder thin'></div>
                      <div className='half-length placeholder thin'></div>
                      <hr />
                      <div className='full-length placeholder thick'></div>
                      <div className='full-length placeholder thin'></div>
                      <div className='third-length placeholder thin'></div>
                      <div className='half-length placeholder thin'></div>
                    </div>
                  </div>
                }

                { this.state.feeds !== null && this.state.feeds.length === 0 
                && <div>There are no news feeds</div> }
                
                { this.state.feeds !== null && this.state.feeds.length > 0
                &&  this.state.feeds.map((feed) => 
                <div key={feed.title}>
                <div className='row' >
                  <div className='col-sm-12'>
                  <h3>
                    - {feed.title}
                  </h3>
                    { renderHTML(feed.description)}
                  <p>
                    <strong>{this.formatDate(feed.date)}</strong> &nbsp;&nbsp;&nbsp;&nbsp;| 
                    <strong> &nbsp;&nbsp;&nbsp;&nbsp;<a href={feed.link} target="_blank">Read article</a></strong>
                  </p>
                </div>
            
                </div>
                <hr />
                </div>
                )}
                
             </div>
          </div>
        );
      }
}

export default News;