import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../styles/components/AgencyCard.css'

class AgencyCard extends Component{
  static propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    link: PropTypes.string,
  }
  render() {
    return (
      <a href={this.props.link} target='_blank'>
        <div className='col-sm-6 col-md-4'>
          <div className='agency-card'>
            <div 
              className='agency-card-img' 
              style={{ background: `url(${this.props.image})`, backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            </div>
            <div className='agency-subcard'>
              <h5>{this.props.service}</h5>
              <p>{this.props.name}</p>
            </div>
          </div>
        </div>
      </a>
    )
  }
}

export default AgencyCard;