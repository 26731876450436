import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import '../styles/views/Contact.css'
import { contact } from '../utils/api'

class Contact extends Component {
    state = {
      fullName: '',
      email: '',
      message: '',
      buttonMsg: 'Send',
      disabled: false,
      error: false,
      success: false
    }
    sendEmail = (e) => {
      e.preventDefault()
      this.setState({
        disabled: true,
        buttonMsg: 'Sending ...'
      })
      const { fullName, email, message } = this.state

      if(fullName.trim() === '' || email.trim() === '' || message.trim() === '' ){
        this.setState({
          error: true,
          disabled: false,
          buttonMsg: 'Send'
        })
        return
      }

      // console.log("Full name is ", this.state.fullName)
      // console.log("Email is ", this.state.email)
      // console.log("Message is ", this.state.message)

      let msg =
      {
        fullName,
        email,
        message,
      }
      contact(msg).then((result) => {
        // console.log("Resss",result)
        if(result.status === "success"){
          this.setState({
            error: false,
            disabled: false,
            buttonMsg: 'Send',
            success: true
          })
        }
      })

    }
    closeError = () => {
      this.setState({
        error: false
      });
    }
    closeSuccess = () => {
      this.setState({
        success: false
      });
    }
    handleFullNameChange = (evt) => {
      this.setState({
        fullName: evt.target.value
      });
    }
    handleEmailChange = (evt) => {
      this.setState({
        email: evt.target.value
      });
    }
    handleMessageChange = (evt) => {
      this.setState({
        message: evt.target.value
      });
    }
    render() {
        return (
          <div className='contact wow fadeIn' data-wow-duration='2s'>
            <div className='container'>
              <div className='row double-row-padding'>
                <div className='col-sm-12'>
                  <h1>
                    Contact Us
                  </h1>
                </div>
                </div>
              </div>
              <div className='container'>
                <div className='row row-padding-bottom'>
                  <div className='col-sm-6'>
                    <p>
                      If you are thinking about doing business in Africa, 
                      looking for an agency or want to get involved with 
                      our thought leadership platforms, drop us a note.  
                      We would love to hear from you. 
                    </p>
                    <br /><br />
                  </div>
                  <div className='col-sm-6'>
                  {this.state.error === true && <div className="alert alert-danger">
                    <a className="close" aria-label="close" onClick={this.closeError}>&times;</a>
                    <strong>Error!</strong> All fields are required.
                  </div>}
                  {this.state.success === true && <div className="alert alert-success">
                    <a className="close" aria-label="close" onClick={this.closeSuccess}>&times;</a>
                    <strong>Message sent!</strong> Thanks for reaching out. We will reply shortly. 
                  </div>}
                    <form>
                      <div className='form-group'>
                        <input 
                          type='text' 
                          className='form-control' 
                          placeholder='Full Name *' 
                          value={this.state.fullName}
                          onChange={this.handleFullNameChange}/>
                      </div>
                      <div className='form-group'>
                        <input 
                          type='email' 
                          className='form-control' 
                          placeholder='Email Address *' 
                          value={this.state.email}
                          onChange={this.handleEmailChange}/>
                      </div>
                      <div className='form-group'>
                        <textarea 
                          className='form-control' 
                          placeholder='Message *'
                          value={this.state.message}
                          onChange={this.handleMessageChange}></textarea>
                      </div>
                      <div className='form-group'>
                        <button className='form-control btn-submit' onClick={this.sendEmail} disabled={this.state.disabled}>{this.state.buttonMsg}</button>
                      </div>
                    </form>
                  </div>
                </div>
                <hr />
                <div className='row row-padding-bottom text-center'>
                  <div className='col-sm-6 address'>
                    <h3>Lagos</h3>
                    <p>15 Sobo Arobiodu Street, Ikeja GRA, Lagos</p>
                    <p><a href="mailto:we@therepublicomgroup.com" target="_blank">we@therepublicomgroup.com</a></p>
                    <p><a href="tel:+23413429133" target="_blank">Tel: +234(0) 1 342 9133</a></p>
                  </div>
                  <div className='col-sm-6 address'>
                    <h3>London</h3>
                    <p>86-90 Paul Street London EC2A 4NE</p>
                    <p><a href="mailto:we@therepublicomgroup.com" target="_blank">we@therepublicomgroup.com</a></p>
                    <p><a href="tel:+442031891276" target="_blank">Tel: +44 20 3189 1276</a></p>
                  
                  </div>
                </div>
              </div>
          </div>
        );
      }
}

export default Contact;