const host = 'https://api.therepublicomgroup.com'

export const news = () =>
  fetch(`${host}/news/all`)
    .then(res => res.json())

export const latest = () =>
    fetch(`${host}/news/latest`)
        .then(res => res.json())

export const contact = (contact) =>
    fetch(`${host}/contact`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify( contact )
    }).then(res => res.json())