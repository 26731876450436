import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Hero from '../components/Hero'
import '../styles/views/Careers.css'
import hero from '../assets/images/about-1.jpg'
import salary from '../assets/images/salary.svg'
import smile from '../assets/images/smile.svg'
import training from '../assets/images/training.svg'
import games from '../assets/images/games.svg'
import lunch from '../assets/images/lunch.svg'
import tribe from '../assets/images/tribe.jpg'
import heart from '../assets/images/heart.svg'
import donate from '../assets/images/donate.svg'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

class Careers extends Component {

    render() {
        return (
          <div className='careers'>
            <div className='container wow fadeIn' data-wow-duration='2s'>
              <div className='row double-row-padding'>
                <div className='col-sm-6'>
                  <h1>
                    Opportunities <br />at <br />Republicom
                  </h1>
                </div>
                <div className='col-sm-6'>
                  <p>
                    If you are not interested in changing the world, 
                    please do not come close to this Republic. 
                    We are challengers, therefore we are always 
                    looking for people who want to change the world. 
                    We are not satisfied with the way things are , 
                    so we are always asking questions. 
                    We are not fixated on titles, 
                    if you are a knowledge-merchant, 
                    we have a place for you on this side
                  </p>
                  <p className='available-positions'>
                    <a href='#jobs'>View available positions &nbsp;&nbsp; ></a>
                  </p>
                  </div>
                </div>
            </div>
            <div className='container'>
              <div className='row row-padding wow fadeIn' data-wow-duration='2s'>
                <div className='col-xs-6 col-md-3 perk-div'>
                  <img src={salary} className='perk-icon' />
                  <p className='text-center'>Competitive salary</p>
                </div>
                <div className='col-xs-6 col-md-3 perk-div'>
                  <img src={smile} className='perk-icon' />
                  <p className='text-center'>Ability to work on interesting projects like Advertising Week and Techplus</p>
                </div>
                <div className='col-xs-6 col-md-3 perk-div'>
                  <img src={training} className='perk-icon' />
                  <p className='text-center'>Global training opportunities</p>
                </div>
                <div className='col-xs-6 col-md-3 perk-div'>
                  <img src={games} className='perk-icon' />
                  <p className='text-center'>Lots of video games, team outings, and fierce ping pong battles</p>
                </div>
                <div className='col-xs-6 col-md-3 perk-div'>
                  <img src={lunch} className='perk-icon' />
                  <p className='text-center'>Catered lunches and a fully stocked kitchen</p>
                </div>
                <div className='col-xs-6 col-md-3 perk-div'>
                  <img src={tribe} className='perk-icon' />
                  <p className='text-center'>Join a tribe</p>
                </div>
                <div className='col-xs-6 col-md-3 perk-div'>
                  <img src={heart} className='perk-icon' />
                  <p className='text-center'>We value our people</p>
                </div><div className='col-xs-6 col-md-3 perk-div'>
                  <img src={donate} className='perk-icon' />
                  <p className='text-center'>We give back</p>
                </div>
              </div>
              <div className='row row-padding wow fadeIn' data-wow-duration='2s'>
                <div className='col-sm-12'>
                  <h2>
                    Working with us
                  </h2>
                  <br />
                </div>
                <div className='col-sm-8'>
                    <h4>
                      Springboard
                    </h4>
                    <p>
                      Springboard is an innovative talent development program that helps 
                      university students break into the advertising, content production 
                      and technology industry. The program is designed to bridge the gap 
                      between classroom education and hands-on-application and to help 
                      participating students differentiate themselves from other 
                      graduating talent. Those selected for the Springboard program are 
                      given the opportunity to work with seasoned professionals and real client work. 
                    </p>
                </div>
              </div>
            </div>
            <div className='container' id="jobs">
              <div className='row row-padding wow fadeIn' data-wow-duration='2s'>
                <div className='col-sm-12'>
                  <h2>
                    Explore Careers
                  </h2>
                  <br />
                </div>
                <div className='col-sm-12 col-md-8'>
                  <div className='job-opportunity'>
                    <button type="button" className="job-title" data-toggle="collapse" data-target="#first">
                      <h3>
                        Art Director <span>(Neukleos)</span>
                        <i className='fa fa-chevron-right pull-right'></i>
                      </h3>
                    </button>
                    <div id="first" className="collapse job-description">
                      <br />
                      <p>
                        We are not your typical agency, <strong>we thrive at the intersection of business, design 
                        and technology.</strong> Creating ideas that move people, change lives and help build brands.
                      </p>
                      <p>
                      Our goal is to innovate and build the future in collaboration with our people, clients and partners.
                      </p>
                      <p>
                        <strong>Our idea is simple.</strong> If the world will hear us, we must sound different and stand out from the crowd. We are local with the aim of creating an indelible international mark with our work.
                        Our fun culture, love of living well and passion for great work makes Image &amp; Time a great place to be every day.
                      </p>
                      <p>Some of our work include the <strong>Union Bank Uncle Thomas</strong> Campaign, <strong>Gulder Na Man You Be</strong> campaign and <strong>etranzact rebrand.</strong> We also work with interesting and bold clients who enable us to produce great work.</p>
                      <p>
                        Responsibilities:
                      </p>
                      <ul>
                        <li>Creates and presents concepts for client campaigns and new business that meet strategic and business goals.</li>
                        <li>Oversees a broad range of digital projects, which can include banners, brand development, mobile apps, video and presentations.</li>
                        <li>Researches, reviews and recommends suitable illustration, photography and sound.</li>
                        <li>Reviews and directs work of project teams, taking quality of design, strategic accuracy, and adherence to client goals and brand style guidelines into account.</li>
                        <li>Presents work internally and to clients.</li>
                        <li>Collaborates with Delivery, Account Services and other internal contacts to incorporate revisions from client and/or team as needed.</li>
                        <li>Reviews quality of final product, packages and delivers final deliverables to project managers and/or clients.</li>
                        <li>Ensures that the scope is clear and agreed upon, driving scope discussions if necessary.</li>
                        <li>Follows creative briefs by interpreting instructions and disseminating results to team members in an effective manner.</li>
                        <li>Manages design resources for approved projects. Delivers products in a timely fashion to meet set deadlines.</li>
                        <li>Oversees and prioritizes work effectively. Keeps management in the loop and effectively escalates roadblocks.</li>
                        <li>Mentors and manages Associate Art Directors and Designers.</li>
                      </ul>
                      <p>Qualifications:</p>
                      <ul>
                        <li>Intermediate/advanced knowledge of MAC OS, CS5, PowerPoint, InDesign, photoshop and illustrator.</li>
                        <li>Working knowledge of HTML, CSS, is a plus and video production.</li>
                        <li>Advanced typography skills, conceptual abilities and understanding of user centric design.</li>
                        <li>Experience with brand development, pitches.</li>
                        <li>Strong verbal communication skills in order to speak effectively before internal teams and clients.</li>
                        <li>Strong interpersonal skills, a positive attitude and the ability to thrive in a collaborative agency environment with multi-disciplinary teams.</li>
                        <li>Ability to successfully organize, prioritize and manage multiple projects in a deadline-driven environment.</li>
                        <li>Ability to guide others in the artistic development and execution of creative interactive marketing communication programs that meet client objectives.</li>
                        <li>Must demonstrate a solid understanding of current creative media, mediums and trends.</li>
                        <li>Supervisory experience is an advantage.</li>
                        <li>Must have a great book/portfolio!</li>
                      </ul>
                      <p>Education & Experience:</p>
                      <ul>
                        <li>A fantastic portfolio of work.</li>
                        <li>Bachelor&#39;s degree (BA/BS) in a design related field e.g. in Fine Arts, Graphic Design, Multi-Media Design, or related field.</li>
                        <li>A minimum of three (3) years of relevant experience in the art/creative field within an advertising agency is required. International experience is a plus</li>
                      </ul>
                      <p>Equivalent combination of education and experience may be considered</p>
                      <br />
                      <p className='more'>
                        <a href="mailto:we@neukleos.com">Apply Now</a>
                      </p>
                      <br />
                    </div>
                  </div>
                  <div className='job-opportunity'>
                    <button type="button" className="job-title" data-toggle="collapse" data-target="#second">
                      <h3>
                        Chief Finance Officer <span>(Republicom)</span>
                        <i className='fa fa-chevron-right pull-right'></i>
                      </h3>
                    </button>
                    <div id="second" className="collapse job-description">
                      <br />
                      <p>
                        <strong>PURPOSE OF THE POSITION</strong>
                      </p>
                      <p>
                        The Chief Finance Officer is responsible for planning, directing 
                        and controlling financial, and administrative functions in the Group 
                        in accordance with Federal and Territorial legislation.
                      </p>
                      <p>
                        <strong>SCOPE</strong>
                      </p>
                      <p>
                        This includes preparing the budget; conducting financial analysis and preparing financial reports; 
                        developing and implementing an effective system of accounting; managing the payroll system and 
                        maintaining accurate and current bylaw records.
                      </p>
                      <p>
                        Failure to provide these services may result in improper budgeting and spending allocations;
                        misplaced or misused revenues; inaccurate financial statements; and financial
                        mismanagement if the payroll, accounts payable and/or accounts payables are
                        not processed in an accurate and timely manner.
                      </p>
                      <p>
                        <strong>RESPONSIBILITIES</strong>
                      </p>
                      <ol>
                        <li>
                          Participate in the provision of effective strategic, financial and human resources planning and information<br />
                          Main Activities:<br />
                          <ul>
                            <li>Provide financial advice and guidance to the GMD</li>
                            <li>Participate in strategic, financial and human resources planning</li>
                            <li>Research, prepare and submit the annual budget</li>
                            <li>Prepare detailed reports on financial, human resources and administrative matters</li>
                            <li>Attend meetings and make presentations to Board</li>
                          </ul>
                        </li>
                        <li>
                          Provide comptrollership functions in order to ensure finances are managed according to legislation, 
                          policies and procedures and generally accepted accounting principles.<br />
                          Main Activities:<br />
                          <ul>
                            <li>Manage accounting and financial systems and maintain full and accurate accounting records</li>
                            <li>Conduct financial analysis and prepare detailed financial reports and statements</li>
                            <li>Provide financial and accounting advice, direction and leadership</li>
                            <li>Ensure compliance with financial legislation, policies and procedures</li>
                            <li>Develop and implement purchasing practices and monitor the purchasing system</li>
                            <li>Prepare tender documents and administer contracts</li>
                            <li>Respond to auditors’ comments concerning finances and operations and oversee required action to address deficiencies</li>
                            <li>Approve the Chart of Accounts and maintain commitment controls</li>
                            <li>Implement and monitor payment authority practices</li>
                            <li>Establish and maintain cash controls</li>
                            <li>Monitor department spending and recommend corrective actions as necessary</li>
                            <li>Manage investments and reserves</li>
                            <li>Reconcile general ledger accounts</li>
                          </ul>
                        </li>
                        <li>
                          Provide leadership and direction to Finance and Administration staff to ensure efficient use of human resources<br />
                          Main Activities: <br />
                          <ul>
                            <li>Establish and maintain internal controls to ensure compliance with financial and human resources legislation, policies and procedures.</li>
                            <li>Evaluate the performance of, and provide training and development opportunities for, Finance and Administration staff</li>
                            <li>Manage the maintenance and upgrade of financial, payroll and human resources information systems</li>
                            <li>Provide leadership by delegating tasks, responding to staff inquiries and providing overall direction to section employees</li>
                          </ul>
                        </li>
                      </ol>
                      <p>
                        <strong>Personal Attributes</strong>
                      </p>
                      <p>
                        The incumbent must maintain strict confidentiality in performing the duties of the Director of Finance. The incumbent must also demonstrate the following personal attributes:
                      </p>
                      <ul>
                        <li>be honest and trustworthy</li>
                        <li>be respectful</li>
                        <li>possess cultural awareness and sensitivity</li>
                        <li>be flexible</li>
                        <li>demonstrate sound work ethics</li>
                      </ul>
                      <p className='more'>
                        <a href="mailto:we@therepublicomgroup.com">Apply Now</a>
                      </p>
                      <br />
                    </div>
                  </div>
                  <div className='job-opportunity'>
                    <button type="button" className="job-title" data-toggle="collapse" data-target="#third">
                      <h3>
                        Sales Manager <span>(AWAfrica)</span>
                        <i className='fa fa-chevron-right pull-right'></i>
                      </h3>
                    </button>
                    <div id="third" className="collapse job-description">
                      <br />
                      <p>
                        <strong>OVERVIEW</strong>
                      </p>
                      <p>
                        Advertising Week is the premier event for marketing, brand, advertising, and technology professionals. 
                        Now in five different major cities across the globe – New York, London, Tokyo, Mexico City and Sydney 
                        – each edition of Advertising Week presents endless opportunities to learn, network and liaise with 
                        the industry’s best. The newest addition to the stable is the Advertising Week Africa which is billed 
                        to take place in the week of October 28th 2019 in the city of Johannesburg, South Africa.</p>
                      <p>
                        <strong>FUNCTION</strong>
                      </p>
                      <p>
                        The Sales Manager is chiefly responsible for the procurement and management of event sponsors and to ensure that the benefits agreed with each sponsor/partner is delivered.
                      </p>
                      <p>
                        <strong>RESPONSIBILITIES</strong>
                      </p>
                      <p>The duties of the Sales Manager include but are not limited to:</p>
                      <ul>
                        <li>Pursuing and securing new local sponsorship sales, expanding beyond existing clients, agencies and contacts.</li>
                        <li>
                          Developing and implementing innovative sponsorship sales strategies, defining what clients are sponsoring 
                          and establishing the unique value proposition in each event offering including building custom brand activation 
                          opportunities based on client needs/goals.
                        </li>
                        <li>
                        Proactively managing the sponsorship sales process with each initiative to ensure financial and marketing goals are met.
                        </li>
                        <li>Tracking sales opportunities through approved CRM system for accurate reporting and stakeholder communication.</li>
                        <li>
                          Working in tandem with local leadership and event account team to manage the process of compiling information 
                          and supporting sales through customized proposal generation including creative development, collecting and 
                          formulating research and building compelling presentations outlining all promotional elements and opportunities 
                          available within each sponsorship event.
                        </li>
                        <li>Overseeing the negotiation and execution of all aspects of contractual agreements related to sponsorships to achieve the greatest benefits for the client and company.</li>
                        <li>Partnering with group and local sales leadership team to foster leads as needed.</li>
                        <li>Collecting and monitoring insights and best practices for key categories to share with key stakeholders.</li>
                        <li>Effectively reporting on account development and sales process with key stakeholders.</li>
                        <li>Partnering with Client Strategy and the B2B Marketing organisations to develop the 
                          business development strategy, data/research mining, inside call center sales, 
                          sales presentation decks, and direct marketing lead generation tactics 
                          (ie, email, social media, event conferences, trade shows, etc).
                        </li>
                        <li>
                          Overseeing event sponsorship brand activation implementation and serve as key 
                          liaison between client and event operations to ensure client on-site activation needs are delivered.
                        </li>
                        <li>
                          Overseeing the creation and presentation of promotional materials to sales team and
                          clients after each sold sponsorship program.</li>
                      </ul>
                      <p>QUALIFICATIONS</p>
                      <ul>
                        <li>Bachelor s degree in business, marketing, advertising or related field or equivalent related event/sponsorship/media experience required.</li>
                        <li>Minimum five years event marketing and/or sponsorship sales experience required.</li>
                        <li>Proven ability to build trusted relationships with key internal audiences, given close working relationships necessary across all products and channels to include editorial, sales and marketing.</li>
                        <li>Proven experience managing partnership marketing efforts with success growing account spend incrementally.</li>
                        <li>Understanding of marketing research techniques and ability to apply findings in face to face interaction with clients.</li>
                        <li>Deep working knowledge of the sales process and a strong general sales acumen, as well as superior relationship development and account management skills.</li>
                        <li>Ability to understand and work within a variety of different business models and non-traditional revenue streams.</li>
                        <li>Deep understanding of digital, mobile and social media space and how marketers utilize these platforms.</li>
                        <li>Ability to flawlessly and dynamically present our value proposition to an audience.</li>
                        <li>Excellent organisation and time management skills with the ability to multi-task and connect across multiple markets simultaneously.</li>
                        <li>Problem solving and interpersonal skills necessary to reach compromises that all parties find acceptable during negotiation.</li>
                        <li>Proven team leader with results-oriented focus.</li>
                        <li>Excellent analytical, written and oral communication &amp; presentation skills.</li>
                      </ul>
                      <p className='more'>
                        <a href='mailto:info@imageandtime.com'>Apply Now</a>
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
}

export default Careers;