import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop'
import Footer from './components/Footer'
import Home from './views/Home'
import About from './views/About'
import Careers from './views/Careers'
import People from './views/People'
import News from './views/News'
import Contact from './views/Contact'
import RepublicomCollege from './views/RepublicomCollege'
import PageNotFound from './views/PageNotFound'

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <ScrollToTop>
            <Fragment>
            <Header />
              <div className='full-body-content'>
                <Switch>
                  <Route path='/' exact component={Home} />
                  <Route path='/about' exact component={About} />
                  <Route path='/careers' exact component={Careers} />
                  <Route path='/people' exact component={People} />
                  <Route path='/news' exact component={News} />
                  <Route path='/contact' exact component={Contact} />
                  <Route path='/republicom-college' exact component={RepublicomCollege} />
                  <Route component={PageNotFound} />
                </Switch>
              </div>
            </Fragment>
          </ScrollToTop>
        </Router>
        <Footer />
      </div>
    );
  }
}

export default App;
